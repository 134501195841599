import Vue from "vue";
import App from "./App.vue";
import "./assets/css/main.css";
import ElementUI from "element-ui";
import router from "./router";
import "./assets/theme/index.css"; // 自定义主题
import { Message } from "element-ui";
import store from "@/store";
import "@/assets/js/iconfont.js";
import QuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import { pcLoginCallBack } from "./api/login";
import echarts from "echarts";
import vuex from "@/store/index";
import { queryEmpRouter } from "./api/companyManage";
import { getDataTimeSec } from "@/utils/index";
import userRoot from '@/utils/root'

Vue.use(QuillEditor);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: "small",
});
Vue.prototype.$message = Message;

const defaultRouters = userRoot.defaultRouters
Vue.prototype.$defaultRouters = defaultRouters;

router.beforeEach((to, form, next) => {
  //login登录授权回调
  if (to.query.code) {
    //保存code 回调成功后 删除
    localStorage.setItem("code", to.query.code);
    let date = new Date();
    date = getDataTimeSec(date);
    //请求扫码回调验证 验证通过保存token
    pcLoginCallBack({
      code: to.query.code,
      state: to.query.state,
      appid: to.query.appid,
    })
      .then((res) => {
        console.log("扫码回调:", res);
        //存储登录用户信息
        localStorage.setItem("info", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("loginDate", res.data.lastLoginTime);
        localStorage.setItem("codePhoen", res.data.phone);
        store.state.loginRoot.loginDate = res.data.lastLoginTime;
        store.state.loginRoot.userInfo = res.data;
        localStorage.removeItem("code");
        router.push("/dashboard");
      })
      .catch((error) => {
        localStorage.removeItem("code");
        Message({ message: error ? error.message : error, type: "error" });
        router.push("/login");
      });
  } else if (to.query.auth_code) {
    //授权回调下放到vue业务页面处理
    next();
  } else {
    if (!localStorage.getItem("code")) {
      if (to.path !== "/login") {
        if (!localStorage.getItem("token")) {
          router.push("/login");
        }
      }
      next();
    }
  }
  let userInfo = JSON.parse(localStorage.getItem("info"));
  
  console.log('>>>>>>> main userInfo', userInfo)
  
  if (userInfo && !userInfo.roleId) {
    queryEmpRouter({}).then((res) => {
		console.log('>>>>>>> main res: {}', res)
      let routers = defaultRouters
      if (res.data) {
        res.data.routerId = JSON.parse(res.data.routerId)
        if (res.data.routerId) {// && res.data.routerId.length
          routers = res.data.routerId
        }
        if (res.data.empId) {
          vuex.commit("setEmpId", res.data.empId);
        }
      }
      console.log('>>>>>>> routers', routers)
      vuex.commit("setRouterArr", routers);
    });
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
